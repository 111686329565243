import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { User as RealmUser } from "realm-web";
import { TorrentSearch } from '../../types';
import { useSnackbar } from "notistack";
import SearchBar from './SearchBar';
import Results from './Results';
import "./index.css";

interface Props {
	mongoUser: RealmUser | null;
}

const Search = ({ mongoUser }: Props) => {
    const [searchResult, setSearchResult] = useState<Array<TorrentSearch>>([]);
    const [searchStatus, setSearchStatus] = useState<number>(0); // 0: not searching, 1: searching, 2: search done, 3: search error
    const { enqueueSnackbar } = useSnackbar()

    const doSearch = (query) => {
        setSearchStatus(1);
        const apiUrl = "https://torrent-search-api.glitch.me/findTorrents";
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({query: query}),
        })
        .then(response => {
            if (!response.ok) {
                console.error(response);
                throw Error(response.statusText);
            }
            return response.json()
        })
        .then(res => {
            if (res.length === 0) {
                res = [{title: "No results returned"}];
                enqueueSnackbar("No torrents found.", {variant: "warning"});
            }
            setSearchResult(res);
            setSearchStatus(2);
        })
        .catch((error) => {
            console.error(error);
            setSearchStatus(3);
            enqueueSnackbar("Error searching for torrents. Please try again later.", {variant: "error"});
        });
    };

    return (
        <div className="search">
            <Results searchResult={searchResult} mongoUser={mongoUser} />
            <div className="bar-background"></div>
            <SearchBar doSearch={doSearch}/>
            {searchStatus === 1 && <CircularProgress size="10vh" className='loading'/>}
        </div>
    );
};

export default Search;