import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { File as FileType } from "../../types";
import { User as RealmUser } from "realm-web";
import { useParams } from "react-router-dom";
import File from "./File";
import "./index.css";

interface Props {
	mongoUser: RealmUser | null;
}

interface Params {
	hash: string;
}

const Files = ({ mongoUser }: Props) => {
	const [files, setFiles] = useState<Array<FileType> | "error">([]);
	const { hash } = useParams<Params>();

	const updateData = useCallback(async () => {
		try {
			if (!mongoUser) return setFiles("error");
			const collection = mongoUser
				.mongoClient("mongodb-atlas")
				.db("utorrent")
				.collection("files");
			const result = await collection.find({ torrent: hash });
			setFiles(result);
		} catch (err) {
			console.error(err);
			setFiles("error");
		}
	}, [mongoUser, hash]);

	useEffect(() => {
		updateData();
		const interval = setInterval(updateData, 10000);
		return () => {
			clearInterval(interval);
		};
	}, [updateData]);

	if (!hash) {
		return <div>404</div>;
	}

	return (
		<div className="Files">
			{files === "error" ? (
				<div id="files-error">error</div>
			) : files.length > 0 ? (
				files.map((file) => (
					<File
						name={file.name}
						status={file.status}
						key={file.name}
					/>
				))
			) : (
				<CircularProgress />
			)}
		</div>
	);
};

export default Files;
