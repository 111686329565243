import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faExclamationTriangle,
	faBomb,
	faQuestion,
	faFile,
} from "@fortawesome/free-solid-svg-icons";
import { jsx } from "@emotion/react";
import "./File.css";

interface Props {
	status: string;
	name: string;
}

const File = ({ status, name }: Props) => {
	let icon: jsx.JSX.Element;
	switch (status) {
		case "undetected":
			icon = <FontAwesomeIcon icon={faCheckCircle} />;
			break;
		case "type-unsupported":
		case "timeout":
		case "confirmed-timeout":
		case "failure":
		case "unknown":
			icon = <FontAwesomeIcon icon={faExclamationTriangle} />;
			break;
		case "Unsafe":
		case "malicious":
		case "suspicious":
			icon = <FontAwesomeIcon icon={faBomb} />;
			break;
		default:
			icon = <FontAwesomeIcon icon={faQuestion} />;
	}
	return (
		<div className="file">
			<div className="file-name">
				<FontAwesomeIcon icon={faFile} /> {name}
			</div>
			<div className="file-status">
				Status: {status} - {icon}
			</div>
		</div>
	);
};

export default File;
