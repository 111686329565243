import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './SearchBar.css';

interface Props {
    doSearch: (arg0: string) => void
}

const SearchBar = ({ doSearch }: Props) => {
    const [searchQuery, setSearchQuery] = useState("");

    const searchChangeHandler = (event) => {
        setSearchQuery(event.target.value);
    };

    const submitHandler = (event) => {
        event.preventDefault();
        doSearch(searchQuery);
    };

    return (
        <div className="bar">
            <form onSubmit={submitHandler} className="search-form">
                <input 
                    type="text" 
                    placeholder="Search Torrents" 
                    value={searchQuery}
                    onChange={searchChangeHandler}
                    className='search-bar'
                />
                <button type='submit' className='search-button'><FontAwesomeIcon icon={faSearch} /></button>
            </form>
        </div>
    );
};

export default SearchBar;