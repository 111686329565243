import React from 'react';
import TorrentDownload from './TorrentDownload';
import { User as RealmUser } from 'realm-web';
import { TorrentSearch } from '../../types';
import './Results.css';

interface Props {
    searchResult: Array<TorrentSearch>,
    mongoUser: RealmUser | null
}

const Results = ({ searchResult, mongoUser }: Props) => {
    return (!searchResult || searchResult.length === 0) ? 
        <div className="results"></div> :
        searchResult[0].title === "No results returned" ? (
            <div className="results"><h3>No Torrents Found. Please try again!</h3></div>
        ) : (
            <div className="results">
                {searchResult.map((result, index) => <TorrentDownload 
                    id={result.id}
                    key={index}
                    size={result.size}
                    title={result.title}
                    peers={result.peers}
                    seeds={result.seeds}
                    magnet={result.magnet}
                    mongoUser={mongoUser}
                />)}
            </div>
        )
};

export default Results;