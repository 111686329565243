import React, { useState, useEffect, FC } from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import * as Realm from "realm-web";
import Search from "./Search";
import NavBar from "./NavBar";
import Files from "./Files";
import Login from "./Login";
import Home from "./Home";
import "./App.css";

const app = new Realm.App({ id: "utorrentscanner-cqvsq" });
const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
});

const App: FC = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const history = useHistory();

	const doLogin = async (
		email: string,
		password: string
	): Promise<string> => {
		if (!email || !password) {
			return "Please enter an email and password";
		}

		try {
			const credentials = Realm.Credentials.emailPassword(
				email,
				password
			);
			const user = await app.logIn(credentials);
			if (user.id !== app.currentUser?.id) {
				return "Failed to log in";
			}
			setIsLoggedIn(true);
		} catch (err: any) {
			return "Failed to log in";
		}
		return "";
	};

	useEffect(() => {
		if (app.currentUser && history.location.pathname === "/login") {
			history.push("/");
			setIsLoggedIn(true);
		}
	}, [isLoggedIn, history]);

	return (
		<ThemeProvider theme={darkTheme}>
			<SnackbarProvider maxSnack={3}>
				<CssBaseline />
				<Switch>
					<Route path="/login">
						<Login doLogin={doLogin} />
					</Route>
					{isLoggedIn && <>
						<Route path="/files/:hash">
							<Files mongoUser={app.currentUser} />
						</Route>
						<Route path="/search">
							<Search mongoUser={app.currentUser} />
						</Route>
						<Route exact path="/">
							<Home mongoUser={app.currentUser} />
						</Route>
					</>}
					<Route path="*">
						{!isLoggedIn ? (
							<Redirect to="/login" />
						) : (
							<Redirect to="/" />
						)}
					</Route>
				</Switch>
				<NavBar />
			</SnackbarProvider>
		</ThemeProvider>
	);
};

export default App;
