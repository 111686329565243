import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faCheckCircle,
	faExclamationTriangle,
	faBomb,
	faSpinner,
	faFolderOpen
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./Torrent.css";

const Torrent = ({ hash, name, statusStr, rerender, mongoUser, downloadProgress }) => {
	const removeTorrent = async (event: any) => {
		event.preventDefault();
		try {
			const downloads = mongoUser
				.mongoClient("mongodb-atlas")
				.db("utorrent")
				.collection("downloads");
			await downloads.deleteOne({ hash: hash });
		} catch (err) {
			console.error(err);
			alert("An Error occured");
		}
		rerender();
	};

	const filesURI = "/files/" + hash;
	let icon = <FontAwesomeIcon icon={faSpinner} />;
	switch (statusStr) {
		case "OK":
			icon = <FontAwesomeIcon icon={faCheckCircle} />;
			break;
		case "Upload Failed":
		case "Some Bad Files":
			icon = <FontAwesomeIcon icon={faExclamationTriangle} />;
			break;
		case "BAD":
			icon = <FontAwesomeIcon icon={faBomb} />;
			break;
		default:
			icon = <FontAwesomeIcon icon={faSpinner} />;
	}

	return (
		<div className="torrent">
			<div className="torrent-name">
				{icon} {name}
			</div>
			{statusStr === "Downloading" ?
				<div className="torrent-status">Status: {statusStr} {downloadProgress}%</div> :
				<div className="torrent-status">Status: {statusStr}</div>
			}
			<div className="torrent-actions">
				<a
					className="torrent-remove"
					href={"."}
					onClick={removeTorrent}
				>
					<FontAwesomeIcon icon={faTrash} onClick={removeTorrent} />
				</a>
				<Link to={filesURI} className="torrent-files">
					<FontAwesomeIcon icon={faFolderOpen} />
				</Link>
			</div>
		</div>
	);
};

export default Torrent;
