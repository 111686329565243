import React from 'react';
import { faSearch, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import "./NavBar.css";

const NavBar = () => {
  return (
    <div className="navbar">
      <Link to="/"><FontAwesomeIcon icon={faHome} /></Link>
      <div className="navbar-title">uTorrent Scanner</div>
      <Link to="/search"><FontAwesomeIcon icon={faSearch} /></Link>
    </div>
  );
};

export default NavBar;