import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

interface Props {
	doLogin: (email: string, password: string) => Promise<string>;
}

const Login = ({ doLogin }: Props) => {
	const [result, setResult] = useState<string>("Enter your email and password to login.");
	const [password, setPassword] = useState<string>("");
	const [email, setEmail] = useState<string>("");

	const handleLogin = async () => {
		const res = await doLogin(email, password);
		if (res) setResult(res);
	};

	const keyListener = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter") {
			handleLogin();
		}
	};

	return (
		<Dialog open={!!result} className="login" onKeyUp={keyListener}>
			<DialogTitle>Login</DialogTitle>
			<DialogContent>
				<TextField
					label="Email"
					variant="outlined"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
					fullWidth
					sx={{ margin: "1rem 0"}}
				/>
				<TextField
					label="Password"
					variant="outlined"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
					fullWidth
					type="password"
					sx={{ margin: "1rem 0"}}
				/>
				<div className="result">{result}</div>
			</DialogContent>
			<DialogActions>
				<Button type="submit" autoFocus disabled={!email || !password} onClick={handleLogin}>Login</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Login;
