import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from '@mui/material';
import { TorrentSearch, Torrent, CategoriesCfg } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import './TorrentDownload.css';

const TorrentDownload = ({ magnet, title, size, peers, seeds, id, mongoUser }: TorrentSearch) => {
    const [categories, setCategories] = useState<string[]>(["Other Films"]);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [category, setCategory] = useState<string>("Other Films");
    const history = useHistory();
    
    const doDownload = (event: any) => {
        event.preventDefault();
        setIsDialogOpen(false)
        if (!mongoUser) { return; }
        const newTorrent: Torrent = {
            name: title,
            hash: id,
            status: 0,
            statusStr: "In Queue",
            path: "/temp",
            magnet: magnet,
            category: category,
            downloadProgress: 0,
        }
        const collection = mongoUser
            .mongoClient("mongodb-atlas")
            .db("utorrent")
            .collection("downloads");
        collection.insertOne(newTorrent).then(() => {
            history.push("/");
        });
    };

    useEffect(() => {
        if (!mongoUser) { return; }
        const collection = mongoUser
            .mongoClient("mongodb-atlas")
            .db("utorrent")
            .collection("config");
        collection.findOne({"_id": "categories"}).then((categoriesCfg: CategoriesCfg) => {
            setCategories(categoriesCfg.names);
        });
    }, [mongoUser]);

    return (<>
        <div className="torrent-download">
            <button type="submit" className="torrent-download-button" onClick={() => setIsDialogOpen(true)}> 
                <FontAwesomeIcon icon={faDownload} />
            </button>
            <h2>{title}</h2>
            <div className="torrent-download-size" >Size: {size}</div>
            <div className="torrent-download-seeds" >Seeds: {seeds}</div>
            <div className="torrent-download-peers" >Peers: {peers}</div>
        </div>
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
            <DialogTitle>Download</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select a category for this download
                </DialogContentText>
                <Select
                    displayEmpty
                    value={category}
                    onChange={(event) => setCategory(event.target.value as string)}
                >
                    <MenuItem value="Other Films">Other Films</MenuItem>
                    {categories.map((category) => <MenuItem key={category} value={category}>{category}</MenuItem>)}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={doDownload} color="primary">
                    Download
                </Button>
                <Button onClick={() => setIsDialogOpen(false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </>);
};

export default TorrentDownload;